import React, { useState, useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { NavLink, useLocation } from 'react-router-dom'
import Sidebar from '../../Components/Sidebar'
import Header from '../../Components/Header'
import ReactDatatable from '@ashvin27/react-datatable';




import { FaPlus,FaTrash } from "react-icons/fa6";

import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom'
import { PlansubscribeDelete, UserplanDetails } from '../../api/api'

const PlanDetails = () => {

    let location = useLocation()
    console.log("fdgdfgdfh", location);
    let Data = location.state
    console.log("datassfsg", Data);
    // edit modal
    const [show, setShow] = useState(false);
    // const [planDays,setPlanDays]= useState([])
    const [errors, setError] = useState()
    const [editData, setEditData] = useState({})
    const [list, setList] = useState([
        {
            id: 1
        }
    ])
    console.log("liststss", list);
    const [showAddPlanDetails, setShowAddPlanDetails] = useState(false);
    const [showEditPlanDetails, setShowEditPlanDetails] = useState(false);
    const [planDays, setPlandays] = useState([])
    // const [editData, setEditData] = useState({})
    const navigate = useNavigate();

    console.log("dghdfghgfhfghgfh", planDays);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleShowAddPlanDetails = () => {
        setShowAddPlanDetails(true)
    }
    const handleCloseAddPlanDetails = () => {
        setShowAddPlanDetails(false)
    }
    const handleShowEditPlanDetails = (record) => {
        setShowEditPlanDetails(true)
        setEditData(record)
    }
    const handleCloseEditPlanDetails = () => {
        setShowEditPlanDetails(false)
    }

    const [deleteId, setDeleteId] = useState('')



    const columns = [
        {
            key: "",
            text: "S.No",
            align: "center",
            className: "text-center w_100",
            sortable: true,
            cell: (record, index) =>
                <p className='text-center'>{index + 1}
                </p>
        },
        {
            key: "User",
            text: "Plan User",
            className: "text-center w_130",
            align: "center",
            sortable: false,
            cell: (record) => {
                return (
                    <p>{record?.User?.name}</p>
                )
            }
        },

        {
            key: "plantype",
            text: "Plan Type",
            className: "text-center w_130",
            align: "center",
            sortable: false,
            cell: (record) => {
                return (
                    <p>{record?.plandays?.planType}</p>
                )
            }
        },
        {
            key: "days",
            text: "Plan Days",
            className: "text-center w_130",
            align: "center",
            sortable: false,
            cell: (record) => {
                return (
                    <p>{record?.planDays}</p>
                )
            }
        },
        {
            key: "price",
            text: "Plan Price",
            className: "text-center w_130",
            align: "center",
            sortable: false,
            cell: (record) => {
                return (
                    <p>{record?.planPrice}</p>
                )
            }
        },
        {
            key: "Start Date",
            text: "Start Date",
            className: "text-center w_130",
            align: "center",
            sortable: false,
            cell: (record) => {
                return (
                    <p>{`${new Date(record?.startDate).toLocaleDateString().replace(/\//g, '-')}`}</p>
                )
            }
        },
        {
            key: "Expiry Date",
            text: "Expiry Date",
            className: "text-center w_130",
            align: "center",
            sortable: false,
            cell: (record) => {
                return (
                    <p>{`${new Date(record?.EndDate).toLocaleDateString().replace(/\//g, '-')}`}</p>


                    // <p>{`${new Date(record?.EndDate).getDate() < 10 ? '0' + new Date(record?.EndDate).getDate() : new Date(record?.EndDate).getDate()}-${new Date(record?.EndDate).getMonth() < 10 ? '0' + new Date(record?.EndDate).getMonth() : new Date(record?.EndDate).getMonth()}-${new Date(record?.EndDate).getFullYear()} ${new Date(record?.EndDate).getHours() < 10 ? '0' + new Date(record?.EndDate).getHours() : new Date(record?.EndDate).getHours()}:${new Date(record?.EndDate).getMinutes() < 10 ? '0' + new Date(record?.EndDate).getMinutes() : new Date(record?.EndDate).getMinutes()}`}</p>
                )
            }
        },
        {
            key: "isActive",
            text: "Active",
            className: "text-center w_130",
            align: "center",
            sortable: false,
            cell: (record) => {
                return (
                    <p className={`text-center ${record?.isActive === "started" ? "green_text" : "red_text"}`}>{record?.isActive}</p>
                )
            }
        },
        // {
        //     key: "action",
        //     text: "Action",
        //     className: "activity",
        //     align: "center",
        //     sortable: false,
        //     cell: (record) => {
        //         return (
        //             <div className='d-flex justify-content-center align-items-center gap-3'>
                      
        //                 <button className='delete_button' onClick={() => { DeleteValue(record?._id) }} >
        //                     <FaTrash />
        //                 </button>
        //             </div>
        //         )
        //     }
        // },

    ];

    const config = {
        page_size: 10,
        length_menu: [10, 20, 50],
        filename: "Emailtemplates",
        no_data_text: "No Email Templates found!",
        language: {
            length_menu: "Show _MENU_ result per page",
            filter: "Filter in records...",
            info: "Showing _START_ to _END_ of _TOTAL_ records",
            pagination: {
                first: "First",
                previous: "Previous",
                next: "Next",
                last: "Last",
            },
        },
        show_length_menu: false,
        show_filter: false,
        show_pagination: false,
        show_info: false,
    };

    const extraButtons = [
        {
            className: "btn btn-primary buttons-pdf",
            title: "Export TEst",
            children: [
                <span>
                    <i className="glyphicon glyphicon-print fa fa-print" aria-hidden="true"></i>
                </span>
            ],
            onClick: (event) => {
                console.log(event);
            },
        },
        {
            className: "btn btn-primary buttons-pdf",
            title: "Export TEst",
            children: [
                <span>
                    <i className="glyphicon glyphicon-print fa fa-print" aria-hidden="true"></i>
                </span>
            ],
            onClick: (event) => {
                console.log(event);
            },
            onDoubleClick: (event) => {
                console.log("doubleClick")
            }
        },
    ]

    useEffect(() => {

        Getuserplan()

    }, [])






    const Getuserplan = async () => {
        try {
            let resp = await UserplanDetails()
            console.log("responses", resp);
            if (resp.status) {
                setPlandays(resp.data.data)
            }
        } catch (error) {
            console.log("error", error);
        }
    }

    const DeleteValue = async (record) => {
        console.log("records_subs", record);
        try {
            let payload = {
                id: record,
            }
            let resp = await PlansubscribeDelete(payload)
            console.log("respmses", resp);
            if (resp.status) {
                toast.success(resp.message)
                Getuserplan()
            }
            else {
                toast.error(resp.message)

            }
        } catch (error) {
            console.log("error", error);
        }
    }
    return (
        <>

            {/* start of modal import */}



            {/* end of modal import */}

            <Container fluid className='common_bg position-relative'>
                <div className='liner'></div>
                <Row>

                    <Col xl={2} lg={0} className='p-0 d-none d-xl-block'>
                        <Sidebar />
                    </Col>
                    <Col xl={10} lg={12}>
                        <Header title={'Plan Details'} />


                        <div className='d-flex justify-content-between align-items-center pt-5'>
                            <p className='text-white mb-0 fw-bolder'>User Subscriptions</p>


                        </div>
                        <div className='common_page_scroller mt-5 pe-2'>

                            <div className='exchange_table_holder plans__tableHolder dashboard_box rounded-3 mt-4'>
                                <ReactDatatable
                                    config={config}
                                    records={planDays}
                                    columns={columns}
                                    extraButtons={extraButtons}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>

            {/* modals */}
            {/* <EditPlans show={show} handleClose={handleClose} editData={editData} /> */}
            {/* end of modals */}

        </>
    )
}

export default PlanDetails