// import config
import axios from '../config/axios';


export const AddBuylink = async (data) => {
    console.log("datadatadatadata", data);

    try {
        const respData = await axios({
            'url': `/admin/addbuyLink`,
            'method': 'post',
            data
        })
        console.log("respDatadata",respData);
      
        return {
            status: respData.data.status,
            message: respData.data.message,
            result: respData.data.result
        }
    } catch (err) {
        console.log(err, "addbuylink__err")
        return {
            status: 'error',
            message: err.response,
        }
    }

}


export const getLink = async () => {

    try {
        const respData = await axios({
            'url': `/admin/getbuyLink`,
            'method': 'get',
        })

        return {
            status: respData.data.status,
            message: respData.data.message,
            result: respData.data.result
        }
    } catch (err) {
        console.log(err, "listlink_err")
        return {
            status: 'error',
            message: err.response.data,
        }
    }

}

export const DeleteBuylink = async (data) => {
    console.log("datadatadatadata", data);

    try {
        const respData = await axios({
            'url': `/admin/DeleteBuyLink`,
            'method': 'post',
            'data': data
        })
        console.log("DeleteCmsDeleteCms", respData.data.status);
        return {
            status: respData.data.status,
            message: respData.data.message,
            result: respData.data.result
        }
    } catch (err) {
        console.log(err, "DeleteBuyLink__err")
        return {
            status: 'error',
            message: err.response.data,
        }
    }
}


export const EditBuylink = async (data) => {


    try {
        const respData = await axios({
            'url': `/admin/editBuylink`,
            'method': 'post',
            'data': data
        })
        return {
            status: respData.data.status,
            message: respData.data.message,
            result: respData.data.result
        }
    } catch (err) {
        console.log(err, "EditBuylink__err")
        return {
            status: 'error',
            message: err.response.data,
        }
    }
}
