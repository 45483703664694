// import config
// import axiosFunc from '../config/axiosFunc';

// import axios from 'axios';
import { axiosFunc } from "../config/common";
import config from "../config/config"


const jwttoken = localStorage.getItem("token")
console.log("jwttoken", jwttoken);



//my axiosFunc


export const Getuserlists = async (data) => {
    console.log("datadatadatadata", data);

    try {
        const respData = await axiosFunc({

            'method': 'GET',
            'url': `${config.BACK_URL}/Userlists`,
            "headers": {
                'Content-Type': 'application/json',
                'Authorization': jwttoken

            },
        })
        console.log("respData", respData.data);
        return respData.data;



    } catch (err) {
        console.log(err, "login__err")
        return err


    }

}

export const GetBlockuserlists = async (data) => {
    console.log("datadatadatadata", data);

    try {
        const respData = await axiosFunc({

            'method': 'GET',
            'url': `${config.ADMIN_URL}/blockeduser`,
            "headers": {
                'Content-Type': 'application/json',
                'Authorization': jwttoken

            },
        })
        console.log("respData", respData.data);
        // const response = DecryptData(respData?.data)
        return respData.data;



    } catch (err) {
        console.log(err, "login__err")
        return err


    }

}

export const GetUnBlockuserlists = async (data) => {
    console.log("datadatadatadata", data);

    try {
        
        const respData = await axiosFunc({

            'method': 'GET',
            'url': `${config.ADMIN_URL}/unblockeduser`,
            "headers": {
                'Content-Type': 'application/json',
                'Authorization': jwttoken

            },
        })
        console.log("respData", respData.data);
        // const response = DecryptData(respData?.data)
        // console.log("respData", response);

        return respData.data;



    } catch (err) {
        console.log(err, "login__err")
        return err


    }

}

export const Blockuser = async (data) => {
    console.log("datadatadatadata", data);

    try {
        const respData = await axiosFunc({

            'method': 'POST',
            'url': `${config.ADMIN_URL}/Adminblockusers`,
            "headers": {
                'Content-Type': 'application/json',
                'Authorization': jwttoken

            },
            "data":data
        })
        console.log("respData", respData.data);
        return respData.data;



    } catch (err) {
        console.log(err, "login__err")
        return err


    }

}

export const adminAddcategory = async (data) => {
    console.log("datadatadatadata", data);

    try {
        const respData = await axiosFunc({

            'method': 'POST',
            'url': `${config.ADMIN_URL}/adminAddcategory`,
            "headers": {
                'Content-Type': 'application/json',
                'Authorization': jwttoken

            },
            "data":data
        })
        console.log("respData", respData.data);
        return respData.data;



    } catch (err) {
        console.log(err, "login__err")
        return err


    }

}

export const categorylist = async (data) => {
    console.log("datadatadatadata", data);

    try {
        const respData = await axiosFunc({

            'method': 'GET',
            'url': `${config.ADMIN_URL}/categorylist`,
            "headers": {
                'Content-Type': 'application/json',
                'Authorization': jwttoken

            },
          
        })
        console.log("respData", respData.data);
        return respData.data;



    } catch (err) {
        console.log(err, "login__err")
        return err


    }

}


export const categorylistadmin = async (data) => {
    console.log("datadatadatadata", data);

    try {
        const respData = await axiosFunc({

            'method': 'GET',
            'url': `${config.ADMIN_URL}/categorylistadmin`,
            "headers": {
                'Content-Type': 'application/json',
                'Authorization': jwttoken

            },
          
        })
        console.log("respData", respData.data);
        return respData.data;



    } catch (err) {
        console.log(err, "login__err")
        return err


    }

}

export const subcategorylist = async (data) => {
    console.log("datadatadatadata", data);

    try {
        const respData = await axiosFunc({

            'method': 'GET',
            'url': `${config.ADMIN_URL}/subcategorylists`,
            "headers": {
                'Content-Type': 'application/json',
                'Authorization': jwttoken

            },
            "params":data
          
        })
        console.log("respData", respData.data);
        return respData.data;



    } catch (err) {
        console.log(err, "login__err")
        return err


    }

}


export const adminAddsubcategory = async (data) => {
    console.log("datadatadatadata", data);
    try {
        const respData = await axiosFunc({
            'method': 'POST',
            'url': `${config.ADMIN_URL}/adminAddsubcategory`,
            "headers": {
                'Content-Type': 'application/json',
                'Authorization': jwttoken
            },
            "data":data
        })
        console.log("respData", respData.data);
        return respData.data;
    } catch (err) {
        console.log(err, "login__err")
        return err
    }
}

//plans
export const PlansList = async () => {
    // console.log("datadatadatadata", data);
    try {
        const respData = await axiosFunc({
            'method': 'GET',
            'url': `${config.ADMIN_URL}/plansLists`,
            "headers": {
                'Content-Type': 'application/json',
                'Authorization': jwttoken
            }
        })
        console.log("respData", respData.data);
        return respData.data;
    } catch (err) {
        console.log(err, "login__err")
        return err
    }
}

export const getplanlists = async () => {
    // console.log("datadatadatadata", data);
    try {
        const respData = await axiosFunc({
            'method': 'GET',
            'url': `${config.ADMIN_URL}/plansListsAdmin`,
            "headers": {
                'Content-Type': 'application/json',
                'Authorization': jwttoken
            }
        })
        console.log("respData", respData.data);
        return respData.data;
    } catch (err) {
        console.log(err, "login__err")
        return err
    }
}

export const Addplans = async (data) => {
    console.log("Addplans", data);
    try {
        const respData = await axiosFunc({
            'method': 'POST',
            'url': `${config.ADMIN_URL}/planAdd`,
            "headers": {
                'Content-Type': 'application/json',
                'Authorization': jwttoken
            },
            "data":data
        })
        console.log("respData", respData.data);
        return respData.data;
    } catch (err) {
        console.log(err, "login__err")
        return err
    }
}

export const Editplans = async (data) => {
    console.log("Addplans", data);
    try {
        const respData = await axiosFunc({
            'method': 'POST',
            'url': `${config.ADMIN_URL}/planEdit`,
            "headers": {
                'Content-Type': 'application/json',
                'Authorization': jwttoken
            },
            "data":data
        })
        console.log("respData", respData.data);
        return respData.data;
    } catch (err) {
        console.log(err, "login__err")
        return err
    }
}


export const deleteData = async (data) => {
    console.log("Addplans", data);
    try {
        const respData = await axiosFunc({
            'method': 'POST',
            'url': `${config.ADMIN_URL}/planDelete`,
            "headers": {
                'Content-Type': 'application/json',
                'Authorization': jwttoken
            },
            "data":data
        })
        console.log("respData", respData.data);
        return respData.data;
    } catch (err) {
        console.log(err, "login__err")
        return err
    }
}

export const planlistDelete = async (data) => {
    console.log("Addplans", data);
    try {
        const respData = await axiosFunc({
            'method': 'POST',
            'url': `${config.ADMIN_URL}/planlistDelete`,
            "headers": {
                'Content-Type': 'application/json',
                'Authorization': jwttoken
            },
            "data":data
        })
        console.log("respData", respData.data);
        return respData.data;
    } catch (err) {
        console.log(err, "login__err")
        return err
    }
}

export const hideshowplan = async (data) => {
    console.log("Addplans", data);
    try {
        const respData = await axiosFunc({
            'method': 'POST',
            'url': `${config.ADMIN_URL}/hideshowplan`,
            "headers": {
                'Content-Type': 'application/json',
                'Authorization': jwttoken
            },
            "data":data
        })
        console.log("respData", respData.data);
        return respData.data;
    } catch (err) {
        console.log(err, "login__err")
        return err
    }
}


//gender add
export const AddEditGender = async (data) => {
    console.log("AddEditGender", data);
    try {
        const respData = await axiosFunc({
            'method': 'POST',
            'url': `${config.ADMIN_URL}/AddEditGender`,
            "headers": {
                'Content-Type': 'application/json',
                'Authorization': jwttoken
            },
            "data":data
        })
        console.log("respData", respData.data);
        return respData.data;
    } catch (err) {
        console.log(err, "login__err")
        return err
    }
}


export const Genderlists = async () => {
    // console.log("datadatadatadata", data);
    try {
        const respData = await axiosFunc({
            'method': 'GET',
            'url': `${config.ADMIN_URL}/Genderlists`,
            "headers": {
                'Content-Type': 'application/json',
                'Authorization': jwttoken
            }
        })
        console.log("respData", respData.data);
        return respData.data;
    } catch (err) {
        console.log(err, "login__err")
        return err
    }
}

//email updates
export const addbulkusers  = async(data)=>{
    console.log("dafatata",data);
    try{
        var resp = await axiosFunc({
          
                'method':'post',
                'url':`${config.ADMIN_URL}/exceltomail`,
                'headers' : {
                    "content-type":"multipart/form-data",

                },
                'data' : data
        })

        return resp.data;
    }
    catch(err){console.log("err in bulk users",err)}
  }

  export const changeMaySent = async(data)=>{
    console.log("token data",data)
    try{
        var resp = await axiosFunc({
            "method":"POST",
            "url":`${config.ADMIN_URL}/changemaysent`,
            "headers":{
              "Content-Type": 'application/json',
  
            
            },
            "data":data,
        })

          return resp.data;
    }
    catch(err){
        console.log('add token err',err)
    }
  }

  export const sendSubscriberMail = async (data) => {
    console.log("sklfs",data)
  try { 
      let respData = await axiosFunc({
          'method': 'POST',
          'url': `${config.ADMIN_URL}/SendMailsinissueUser`,
          "headers":{
            "Content-Type": 'application/json',

        },
        "data":data,
      });

    //   let response = Decryptdata(respData?.data)
      return respData.data;
  }catch (error) {
    console.log('add token err',error)

  }
}

export const getSubscribers  = async()=>{
    
    try{
        var resp = await axiosFunc({
          
                'method':'GET',
                'url':`${config.ADMIN_URL}/getSubscribers`,
        })
        console.log("tokonwer resp",resp.data)
      
        
        return resp.data;
    }
    catch(err){console.log("err in gettok owner",err)}
  }

  export const getHelplists  = async()=>{
    
    try{
        var resp = await axiosFunc({
          
                'method':'GET',
                'url':`${config.ADMIN_URL}/getHelplists`,
        })
        console.log("tokonwer resp",resp.data)
      
        
        return resp.data;
    }
    catch(err){console.log("err in gettok owner",err)}
  }

  export const getfeedbacklist  = async()=>{
    
    try{
        var resp = await axiosFunc({
          
                'method':'GET',
                'url':`${config.ADMIN_URL}/getfeedbacklist`,
        })
        console.log("tokonwer resp",resp.data)
      
        
        return resp.data;
    }
    catch(err){console.log("err in gettok owner",err)}
  }

  export const userhelpdelete  = async(data)=>{
    
    try{
        var resp = await axiosFunc({
          
                'method':'POST',
                'url':`${config.ADMIN_URL}/UserHelpdelete`,
                "data":data
        })
        console.log("tokonwer resp",resp.data)
      
        
        return resp.data;
    }
    catch(err){console.log("err in gettok owner",err)}
  }

  export const userfeedbackdelete  = async(data)=>{
    
    try{
        var resp = await axiosFunc({
          
                'method':'POST',
                'url':`${config.ADMIN_URL}/Userfeedbackdelete`,
                "data":data
        })
        console.log("tokonwer resp",resp.data)
      
        
        return resp.data;
    }
    catch(err){console.log("err in gettok owner",err)}
  }

  //Group lists
  export const UnblockGrouplists  = async()=>{
    
    try{
        var resp = await axiosFunc({
          
                'method':'GET',
                'url':`${config.ADMIN_URL}/UnblockGrouplists`,
                
        })
        console.log("tokonwer resp",resp.data)
      
        
        return resp.data;
    }
    catch(err){console.log("err in gettok owner",err)}
  }

  export const BlockGrouplists  = async()=>{
    
    try{
        var resp = await axiosFunc({
          
                'method':'GET',
                'url':`${config.ADMIN_URL}/BlockGrouplists`,
                
        })
        console.log("tokonwer resp",resp.data)
      
        
        return resp.data;
    }
    catch(err){console.log("err in gettok owner",err)}
  }

  export const BlockGroups = async (data) => {
    console.log("datadatadatadata", data);

    try {
        const respData = await axiosFunc({

            'method': 'POST',
            'url': `${config.ADMIN_URL}/Adminblockgroups`,
            "headers": {
                'Content-Type': 'application/json',
                'Authorization': jwttoken

            },
            "data":data
        })
        console.log("respData", respData.data);
        return respData.data;



    } catch (err) {
        console.log(err, "login__err")
        return err


    }

}

export const editemailTemplateList = async (data) => {
    const resp = await axiosFunc({
        method: 'POST',
        url: `${config.ADMIN_URL}/editemailtemplate`,
        "headers": {
            "Content-Type": 'application/json',
            'Authorization': jwttoken

        },
        data: data,
    })
    console.log("ressss", resp.data);
    // let response = Decryptdata(resp?.data)
    return resp.data;

}

export const getemailTemplateList = async () => {
    const resp = await axiosFunc({
        method: 'GET',
        url: `${config.ADMIN_URL}/getEmailTemplateList`,
        "headers": {
            "Content-Type": 'application/json',

        },
    })
    console.log("ressss", resp.data);
    return resp.data;
}

export const UserplanDetails = async () => {
    const resp = await axiosFunc({
        method: 'GET',
        url: `${config.ADMIN_URL}/userplanlists`,
        "headers": {
            "Content-Type": 'application/json',

        },
    })
    console.log("ressss", resp.data);
    return resp.data;
}

export const GetEvents = async (data) => {
    console.log("datadatadatadata", data);

    try {
        
        const respData = await axiosFunc({

            'method': 'GET',
            'url': `${config.ADMIN_URL}/Eventlists`,
            "headers": {
                'Content-Type': 'application/json',
                'Authorization': jwttoken

            },
        })
        console.log("respData", respData.data);
        // const response = DecryptData(respData?.data)
        return respData.data;



    } catch (err) {
        console.log(err, "login__err")
        return err


    }

}

export const GetblockEvents = async (data) => {
    console.log("datadatadatadata", data);

    try {
        
        const respData = await axiosFunc({

            'method': 'GET',
            'url': `${config.ADMIN_URL}/BlockEventlists`,
            "headers": {
                'Content-Type': 'application/json',
                'Authorization': jwttoken

            },
        })
        console.log("respData", respData.data);
        // const response = DecryptData(respData?.data)
        return respData.data;



    } catch (err) {
        console.log(err, "login__err")
        return err


    }

}


export const Adminblockevents = async (data) => {
    console.log("datadatadatadata", data);

    try {
        const respData = await axiosFunc({

            'method': 'POST',
            'url': `${config.ADMIN_URL}/Adminblockevents`,
            "headers": {
                'Content-Type': 'application/json',
                'Authorization': jwttoken

            },
            "data":data
        })
        console.log("respData", respData.data);
        return respData.data;



    } catch (err) {
        console.log(err, "login__err")
        return err


    }

}

export const SubhobbyDelete = async (data) => {
    console.log("Addplans", data);
    try {
        const respData = await axiosFunc({
            'method': 'POST',
            'url': `${config.ADMIN_URL}/HobbiesDelete`,
            "headers": {
                'Content-Type': 'application/json',
                'Authorization': jwttoken
            },
            "data":data
        })
        console.log("respData", respData.data);
        return respData.data;
    } catch (err) {
        console.log(err, "login__err")
        return err
    }
}


export const EventDelete = async (data) => {
    console.log("Addplans", data);
    try {
        const respData = await axiosFunc({
            'method': 'POST',
            'url': `${config.ADMIN_URL}/eventdelete`,
            "headers": {
                'Content-Type': 'application/json',
                'Authorization': jwttoken
            },
            "data":data
        })
        console.log("respData", respData.data);
        return respData.data;
    } catch (err) {
        console.log(err, "login__err")
        return err
    }
}

export const PlansubscribeDelete = async (data) => {
    console.log("Addplans", data);
    try {
        const respData = await axiosFunc({
            'method': 'POST',
            'url': `${config.ADMIN_URL}/plansubscribelistdelete`,
            "headers": {
                'Content-Type': 'application/json',
                'Authorization': jwttoken
            },
            "data":data
        })
        console.log("respData", respData.data);
        return respData.data;
    } catch (err) {
        console.log(err, "login__err")
        return err
    }
}