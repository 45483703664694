import React, { useRef, useState, useEffect } from 'react'
import Header from '../Components/Header'
import { Col, Container, Row } from 'react-bootstrap'
import Sidebar from '../Components/Sidebar'
// import Lottie from "lottie-react";
// import bitcoin from '../assets/lotties/ye.json'
// import thunder from '../assets/lotties/u.json'
// import Select from "react-select";
// import ReactApexChart from 'react-apexcharts'
// import ReactDatatable from '@ashvin27/react-datatable';
import { useSelector, useDispatch } from 'react-redux'
import { toastAlert } from "../lib/toastAlert";


import { FaRegUser, FaPeopleGroup } from "react-icons/fa6";
import CountUp from 'react-countup';
import { FaPlus, } from "react-icons/fa";
import { RiSecurePaymentLine } from "react-icons/ri";
import { useNavigate } from 'react-router-dom';
import { SlLike } from "react-icons/sl";
import { GetGroups, GetHobbies, GetTransactions, GetUsers } from '../api/cms';




function Dashboard() {

  const switcher = useSelector(state => state?.reducer?.switcher)
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [list, setList] = useState({})
  const [listPriceChart, setlistPriceChart] = useState({})
  const coinRef = useRef()
  const ref = useRef();
  const [chartPeriod, setChartPeriod] = useState('1h')
  const [errors, setError] = useState()
  const [closer, setCloser] = useState();
  const [chartData, setchartData] = useState([])
  const [lable, setlable] = useState()
  const [users,setUsers] = useState()
  const [groups,setGroups] = useState()

  const [interests,setInterests] = useState()

  const [transactions,setTransactions] = useState()

  const [selected, setSelected] = useState({
    value: "Bitcoin",
    label: "Bitcoin",
    img: require("../assets/images/bitcoin.svg"),
  });
  const options = [
    {
      value: "delete",
      label: "Delete",
    },
    {
      value: "read",
      label: "Read",
    },
    {
      value: "save",
      label: "Save",
    },
  ];

  const options2 = [
    {
      value: "thunder",
      label: "Thunder",
      img: require("../assets/images/viothunder.svg"),
    },
    {
      value: "bitcoin",
      label: "Bitcoin",
      img: require("../assets/images/bitcoin.svg"),
    },
  ];
  const customOptionRenderer = ({ label, data, value }) => (
    <div
      className="market_select_component p-2"
      onClick={() => {
        setSelected({ label, value, img: data.img });
        coinRef.current.blur();
        setCloser(!closer);
      }}
    >
      {console.log("sdhguisd", label, data, value)}
      <img src={data.img.default} style={{ width: "25px", marginRight: "10px" }} />
      {label}
    </div>
  );

  // var [lineDatas, setlineDatas] = useState({
  //   options: {
  //     colors: [`${switcher ? '#7638EF' : '#FF8606'}`],
  //     chart: {
  //       height: 300,
  //       type: 'area',
  //       foreColor: '#fff',
  //       toolbar: {
  //         show: false
  //       }
  //     },
  //     grid: {
  //       yaxis: {
  //         lines: {
  //           show: false
  //         },
  //         border: {
  //           show: false
  //         }
  //       },
  //       xaxis: {
  //         lines: {
  //           show: false
  //         },
  //         border: {
  //           show: false
  //         },
  //       },
  //     },

  //     dataLabels: {
  //       enabled: false,
  //     },
  //     stroke: {
  //       curve: 'smooth',
  //     },
  //     xaxis: {
  //       type: 'text',
  //       categories: ['08:00 AM', '09:00 AM', '10:00 AM', '11:00 AM', '12:00 PM']
  //     },
  //     yaxis: {
  //       type: 'text',
  //       data: ['kk', 'sdfdf', 'dfdf', 'dfd']
  //     },
  //     tooltip: {
  //       x: {
  //         format: 'dd/MM/yy HH:mm',
  //       },
  //     },
  //   },
  //   stroke: {
  //     colors: ['transperant']
  //   },
  //   fill: {
  //     colors: ['#FF8606'],
  //     background: ['#FF8606'],
  //   },
  //   series: [
  //     {
  //       name: 'series1',
  //       data: ['34.55k', '40k', '28k', '51k', '42k'],
  //     },
  //   ],
  // });


  const lineData = {
    options: {
      colors: [`${switcher ? '#7638EF' : '#FF8606'}`],
      chart: {
        height: 300,
        type: 'area',
        foreColor: '#fff',
        toolbar: {
          show: false
        }
      },
      annotations: {
        yaxis: [
          {
            y: 0,
            strokeDashArray: 0,
            borderColor: "#000",
            fillColor: "#FFF",
            opacity: 0.8,
            offsetX: 0,
            offsetY: 0
          }
        ]
      },
      xaxis: {
        categories: lable,
        labels: {
          style: {
            colors: "#fff",
          },
          rotate: -90
        }
      },
      grid: {
        yaxis: {
          lines: {
            show: false
          },
          border: {
            show: false
          }
        },
        xaxis: {
          lines: {
            show: false
          },
          border: {
            show: false
          },
        },
      },

      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        type: 'text',
        categories: lable
      },
      yaxis: {
        type: 'text',
        data: ['kk', 'sdfdf', 'dfdf', 'dfd']
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm',
        },
      },
    },
    stroke: {
      colors: ['transperant']
    },
    fill: {
      colors: ['#FF8606'],
      background: ['#FF8606'],
    },
    series: [
      {
        name: 'series1',
        data: chartData,
      },
    ],
  };

  const state = {
    records: [
      {
        "name": <div className='dash_table_left d-flex justify-content-start align-items-centre gap-2'>
          <img src={require('../assets/images/greenarrow.svg').default} className='img-fluid dash_table_arrowImg' />
          <div className='dash_table_statusHolder'>
            <p className='dash_table_status'>Send</p>
            <p className='dash_graymed_text'>Feb 03 . 1043 AM</p>
          </div>
        </div>,
        "value": <div className='dash_table_left'>
          <div className='dash_table_statusHolder text-end'>
            <p className='dash_table_status'>+0.000253 {switcher ? 'LN' : "BTC"}</p>
            <p className='dash_graymed_text'>Confirmed</p>
          </div>
        </div>,

      },
      {
        "name": <div className='dash_table_left d-flex justify-content-start align-items-centre gap-2'>
          <img src={require('../assets/images/redarrow.svg').default} className='img-fluid dash_table_arrowImg' />
          <div className='dash_table_statusHolder'>
            <p className='dash_table_status'>Receive</p>
            <p className='dash_graymed_text'>Feb 03 . 1043 AM</p>
          </div>
        </div>,
        "value": <div className='dash_table_left'>
          <div className='dash_table_statusHolder text-end'>
            <p className='dash_table_status'>+0.000253 {switcher ? 'LN' : "BTC"}</p>
            <p className='dash_graymed_text'>Confirmed</p>
          </div>
        </div>,

      },

    ]
  }

  const columns = [
    {
      key: "name",
      className: "name",
      align: "left",
      sortable: false,
    },
    {
      key: "value",
      className: "address",
      align: "right",
      sortable: false,

    },
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Emailtemplates",
    no_data_text: "No Email Templates found!",
    language: {
      length_menu: "Show _MENU_ result per page",
      filter: "Filter in records...",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: false,
    show_filter: false,
    show_pagination: false,
    show_info: false,
  };

  const extraButtons = [
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i className="glyphicon glyphicon-print fa fa-print" aria-hidden="true"></i>
        </span>
      ],
      onClick: (event) => {
        console.log(event);
      },
    },
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i className="glyphicon glyphicon-print fa fa-print" aria-hidden="true"></i>
        </span>
      ],
      onClick: (event) => {
        console.log(event);
      },
      onDoubleClick: (event) => {
        console.log("doubleClick")
      }
    },
  ]

  const styles2 = {
    option: (styles, { isFocused, isSelected, isHovered }) => ({
      ...styles,
      color: "#fff",
      background: isFocused
        ? "#00CABA"
        : isSelected
          ? "#00CABA"
          : isHovered
            ? "red"
            : "#00CABA",

      zIndex: 1,
      cursor: "pointer",
      fontSize: "13px",
    }),

    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#16EBC3"
          : isSelected
            ? "#16EBC3"
            : isFocused
              ? "#16EBC3"
              : "#151515",
        cursor: "pointer",
        color: isHovered
          ? "#000"
          : isSelected
            ? "#000"
            : isFocused
              ? "#000"
              : "#fff",
        fontSize: "15px",
      };
    },
    indicatorSeparator: (styles) => ({ display: 'none' }),
    valueContainer: (provided, { isFocused, isSelected, isHovered }) => ({
      ...provided,
      height: "40px",
      paddingLeft: '60px',
      border: "none",
      backgroundColor: isHovered
        ? "transperant"
        : isSelected
          ? "transperant"
          : isFocused
            ? "transperant"
            : "transperant",
      // border: "1px solid rgba(34, 34, 34, 0.32)",
      borderRadius: 5,
      fontSize: "15px",
      color: "#fff",
    }),
    control: (provided, { isFocused, isSelected, isHovered }) => ({
      ...provided,
      height: "40px",
      maxWidth: '150px',
      border: "none",
      borderRadius: 5,
      backgroundColor: isHovered
        ? "transperant"
        : isSelected
          ? "transperant"
          : isFocused
            ? "transperant"
            : "transperant",
      // backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
      color: "#fff",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      width: '0px',
      position: "absolute",
      right: 0,
      top: 0,
      border: 'none',
      color: "#6C6A81",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#fff",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };

  const styles = {
    option: (styles, { isFocused, isSelected, isHovered }) => ({
      ...styles,
      color: "#fff",
      background: isFocused
        ? "#161414"
        : isSelected
          ? "#161414"
          : isHovered
            ? "161414"
            : "#141313",

      zIndex: 1,
      cursor: "pointer",
      fontSize: "13px",
    }),

    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#161414"
          : isSelected
            ? "#161414"
            : isFocused
              ? "#161414"
              : '#141313',
        cursor: "pointer",
        color: isHovered
          ? "#fff"
          : isSelected
            ? "#fff"
            : isFocused
              ? "#fff"
              : '#fff',
        fontSize: "13px",
      };
    },

    indicatorSeparator: () => ({ display: 'none' }),
    valueContainer: (provided, { isFocused, isSelected, isHovered }) => ({
      ...provided,
      height: "40px",
      border: "none",
      width: '100px',
      backgroundColor: isHovered
        ? "#141313"
        : isSelected
          ? "#141313"
          : isFocused
            ? "#141313"
            : "#141313",
      // border: "1px solid rgba(34, 34, 34, 0.32)",
      borderRadius: 5,
      fontSize: "13px",
      color: "#fff",
    }),
    control: (provided, { isFocused, isSelected, isHovered }) => ({
      ...provided,
      height: "40px",
      border: "none",
      borderRadius: 5,
      backgroundColor: isHovered
        ? "transperant"
        : isSelected
          ? "transperant"
          : isFocused
            ? "transperant"
            : "transperant",
      // backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
      color: "#fff",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      border: "none",
      color: "#6C6A81",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#343434",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };
  useEffect(() => {

    getUsers();
    getGroups();
    getHobbies();
    getTransactions();
  }, [])




const getUsers = async()=>{
  try {
    let resp = await GetUsers()
    console.log("responses",resp);
    setUsers(resp.data)
  } catch (error) {
    console.log("error");
  }
}

const getGroups = async()=>{
  try {
    let resp = await GetGroups()
    console.log("responses",resp);
    setGroups(resp.data)

  } catch (error) {
    console.log("error");
  }
}
const getHobbies = async()=>{
  try {
    let resp = await GetHobbies()
    console.log("responses",resp);
    setInterests(resp.data)

  } catch (error) {
    console.log("error");
  }
}
const getTransactions = async()=>{
  try {
    let resp = await GetTransactions()
    setTransactions(resp.data)

    console.log("responses",resp);
  } catch (error) {
    console.log("error");
  }
}


  const handleTotalUserNavigate = () => {
    navigate('/users')
  }
  const handleTotalGroupNavigate = () => {
    navigate('/groups')
  }
  const handleInterestNavigate = () => {
    navigate('/sub-category')
  }
  const handleTransactionsNavigate = () => {
    navigate('/userplanlist')
  }


  console.log("listPriceChart", listPriceChart, lable, chartData);

  return (
    <>
      <Container fluid className='common_bg position-relative'>
        <div className='liner'></div>
        <Row>
          <Col xl={2} lg={0} className='p-0 d-none d-xl-block'>
            <Sidebar />
          </Col>
          <Col xl={10} lg={12}>
            <Header title={'Dashboard'} switcher={switcher} />

            {/* <div className='common_page_scroller mt-4 mt-sm-5 pe-2'>
              <Row className=''>
                <Col lg={6} md={6} className='mb-4 mb-lg-0'>
                  <Row className={`${switcher ? 'flex-column-reverse flex-sm-row-reverse flex-md-column-reverse flex-lg-row-reverse' : ''}`}>
                    <Col lg={6} md={12} sm={6} className='mb-4 mb-lg-0'>
                      <div className='dashboard_box p-3 rounded-3 d-flex flex-column gap-4'>
                        <div className='dashbox_topflex d-flex justify-content-between align-items-center'>
                          <div className='dash_lottie_holder position-relative'>
                            <Lottie animationData={bitcoin} className="dash_lottie" loop={true} />
                            <img src={require('../assets/images/bitcoin.svg').default} className='img-fluid dash_coinImg' />
                          </div>
                          <div className='dash_greenvalues d-flex justify-content-end align-items-center gap-2'>
                            <p className='dash_greentext'>{list?.BtcPercentage} %</p>
                            <img src={require('../assets/images/greenthunder.svg').default} className='img-fluid' />
                          </div>
                        </div>
                        <div className='dashbox_bottomflex'>
                          <div className=''>
                            <p className='dash_graymed_text text-center'>Bitcoin</p>
                            <p className='dash_valuewhite_text mt-1 text-center'>$ {list?.bitcoinPrice}</p>
                          </div>


                        </div>
                      </div>
                    </Col>

                    <Col lg={6} md={12} sm={6} className='mb-4 mb-lg-0'>
                      <div className='dashboard_box p-3 rounded-3 d-flex flex-column gap-4'>
                        <div className='dashbox_topflex d-flex justify-content-between align-items-center'>
                          <div className='dash_lottie_holder position-relative'>
                            <Lottie animationData={thunder} className="dash_lottie" loop={true} />
                            <img src={require('../assets/images/viothunder.svg').default} className='img-fluid dash_coinImg' />
                          </div>
                          <div className='dash_greenvalues d-flex justify-content-end align-items-center gap-2'>
                            <p className='dash_greentext'>{list?.BtcLigtningPercentage} %</p>
                            <img src={require('../assets/images/greenthunder.svg').default} className='img-fluid' />
                          </div>
                        </div>
                        <div className='dashbox_bottomflex'>
                          <div className=''>
                            <p className='dash_graymed_text text-center'>Lighting Network</p>
                            <p className='dash_valuewhite_text mt-1 text-center'>$ {list?.bitcoinLNPrice}</p>
                          </div>

                        </div>
                      </div>
                    </Col>


                  </Row>

                  <div className='mt-5'>
                    <Row className='justify-content-between align-items-end'>
                      <Col lg={5} md={5} sm={5} className='mb-4 mb-sm-0 d-flex justify-content-center justify-content-sm-start align-items-center'>
                        <div className='dash_select_holder position-relative'>
                          <div className='dash_lottie_holder dash_select_lottie_holder'>
                            <Lottie animationData={switcher ? thunder : bitcoin} className="dash_lottie dash_select_lottie" loop={true} />
                            <img src={switcher ? require('../assets/images/viothunder.svg').default : require('../assets/images/bitcoin.svg').default} className='img-fluid dash_coinImg dash_select_coinImg' />
                          </div>
                          <Select
                            className=""
                            defaultValue={selected}
                            onChange={(e, days) => { listChart(e, days) }}
                            options={options2}
                            value={selected}
                            components={{ Option: customOptionRenderer }}
                            ref={coinRef}
                            styles={styles2}
                            placeholder=""
                          />
                          <p className='dash_valuewhite_text mt-3'>$ 1,42,0000</p>
                        </div>
                      </Col>
                      <Col lg={6} md={7} sm={6} className='d-flex flex-column justify-content-start align-items-center align-items-sm-end gap-3'>


                        <div className='dash_graph_tabs_holder d-flex justify-content-end align-items-center gap-2'>

                          <button className={`dash_graph_btn rounded-1 ${chartPeriod == '1d' ? 'active' : ''}`} onClick={() => { setChartPeriod('1d'); listChart(selected, '1D') }}>1D</button>
                          <button className={`dash_graph_btn rounded-1 ${chartPeriod == '1m' ? 'active' : ''}`} onClick={() => { setChartPeriod('1m'); listChart(selected, '1M') }}>1M</button>
                          <button className={`dash_graph_btn rounded-1 ${chartPeriod == '1y' ? 'active' : ''}`} onClick={() => { setChartPeriod('1y'); listChart(selected, '1Y') }}>1Y</button>

                        </div>
                      </Col>
                    </Row>

                    <div className='dashboard_box rounded-3 p-2 mt-3'>
                      <ReactApexChart
                        options={lineData?.options}
                        series={lineData?.series}
                        type="area"
                        height={250}
                      />
                    </div>
                  </div>
                </Col >

                <Col lg={6} md={6} className='mb-3 mb-lg-0'>
                  <div className='dash_datatable dashboard_box rounded-3 px-2'>
                    <div className='dash_table_titleFlex d-flex justify-content-between align-items-center mt-3 px-2'>
                      <p className='dash_table_title'>Transaction</p>
                    </div>
                    <div className='dash_datatable_holder'>
                      <ReactDatatable
                        config={config}
                        records={state.records}
                        columns={columns}
                        extraButtons={extraButtons}
                      />
                    </div>
                  </div>
                </Col>
                <Col />
              </Row >
            </div > */}

            <div div className='cn_dashboard py-5' >
              <div className='cn_dashboard_grid'>

                <div className='dashboard_box yellow p-3 rounded-3' onClick={handleTotalUserNavigate}>
                  <div className='d-flex align-items-center justify-content-between '>
                    <div className='cn_dashboard_icon_wrapper yellow'>
                      <FaRegUser />
                    </div>
                    {/* <div className='dash_greenvalues d-flex justify-content-end align-items-center gap-2'>
                      <p className='dash_greentext'>10 %</p>
                      <img src={require('../assets/images/greenthunder.svg').default} className='img-fluid' />
                    </div> */}

                  </div>

                  <div className='mt-4'>
                    <p className='mb-0 dash_title text-center'>Total Users</p>
                    <p className='cn_dashboard_count yellow text-center mb-0'>
                      <CountUp
                        // start={10423 - 300}
                        end={users}
                        duration={2.75}
                      // separator=" "
                      // decimals={4}
                      // decimal=","
                      // prefix="EUR "
                      // suffix=" left"
                      // onEnd={() => console.log('Ended! 👏')}
                      // onStart={() => console.log('Started! 💨')}
                      />
                      {/* <FaPlus className='mb-1' /> */}
                    </p>


                  </div>


                </div>

                <div className='dashboard_box blue p-3 rounded-3' onClick={handleTotalGroupNavigate}>
                  <div className='d-flex align-items-center justify-content-between '>
                    <div className='cn_dashboard_icon_wrapper blue'>
                      <FaPeopleGroup />
                    </div>
                    {/* <div className='dash_greenvalues d-flex justify-content-end align-items-center gap-2'>
                      <p className='dash_greentext'>30 %</p>
                      <img src={require('../assets/images/greenthunder.svg').default} className='img-fluid' />
                    </div> */}

                  </div>

                  <div className='mt-4'>
                    <p className='mb-0 dash_title text-center'>Total Groups</p>
                    <p className='cn_dashboard_count blue text-center mb-0'>
                      <CountUp
                        // start={1245 - 300}
                        end={groups}
                        duration={2.75}
                      // separator=" "
                      // decimals={4}
                      // decimal=","
                      // prefix="EUR "
                      // suffix=" left"
                      // onEnd={() => console.log('Ended! 👏')}
                      // onStart={() => console.log('Started! 💨')}
                      />
                      {/* <FaPlus className='mb-1' /> */}
                    </p>


                  </div>


                </div>
                <div className='dashboard_box violet p-3 rounded-3' onClick={handleInterestNavigate}>
                  <div className='d-flex align-items-center justify-content-between '>
                    <div className='cn_dashboard_icon_wrapper violet'>
                      <SlLike />
                    </div>
                    {/* <div className='dash_greenvalues d-flex justify-content-end align-items-center gap-2'>
                      <p className='dash_redtext'>20 %</p>
                      <img src={require('../assets/images/decrease.svg').default} className='img-fluid' />
                    </div> */}

                  </div>

                  <div className='mt-4'>
                    <p className='mb-0 dash_title text-center'>Hobbies</p>
                    <p className='cn_dashboard_count violet text-center mb-0'>
                      <CountUp
                        // start={32 - 25}
                        end={interests}
                        duration={2.75}
                      // separator=" "
                      // decimals={4}
                      // decimal=","
                      // prefix="EUR "
                      // suffix=" left"
                      // onEnd={() => console.log('Ended! 👏')}
                      // onStart={() => console.log('Started! 💨')}
                      />
                      {/* <FaPlus className='mb-1' /> */}
                    </p>


                  </div>


                </div>

                <div className='dashboard_box green p-3 rounded-3' onClick={handleTransactionsNavigate}>
                  <div className='d-flex align-items-center justify-content-between '>
                    <div className='cn_dashboard_icon_wrapper green'>
                      <RiSecurePaymentLine fontSize={20} />
                    </div>
                    {/* <div className='dash_greenvalues d-flex justify-content-end align-items-center gap-2'>
                      <p className='dash_greentext'>30 %</p>
                      <img src={require('../assets/images/greenthunder.svg').default} className='img-fluid' />
                    </div> */}

                  </div>

                  <div className='mt-4'>
                    <p className='mb-0 dash_title text-center'>Transactions</p>
                    <p className='cn_dashboard_count green text-center mb-0'>
                      <CountUp
                        // start={7542 - 300}
                        end={transactions}
                        duration={2.75}
                      // separator=" "
                      // decimals={4}
                      // decimal=","
                      // prefix="EUR "
                      // suffix=" left"
                      // onEnd={() => console.log('Ended! 👏')}
                      // onStart={() => console.log('Started! 💨')}
                      />
                      {/* <FaPlus className='mb-1' /> */}
                    </p>


                  </div>


                </div>
              </div>

            </div >

          </Col >
        </Row >
      </Container >
    </>
  )
}

export default Dashboard