import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Sidebar from '../Components/Sidebar'
import Header from '../Components/Header'

function AddNetwork() {
  return (
    <>
    <Container fluid className='common_bg position-relative'>
    <div className='liner'></div>
    <Row>
        <Col xl={2} lg={0} className='p-0 d-none d-xl-block'>
            <Sidebar/>
        </Col>
        <Col xl={10} lg={12} className='pe-3'>
            <Header title={'Add Network'} />
            <div className='common_page_scroller mt-4 mt-sm-5 pe-2'>
            <Row className='profile_holder'>
                    <Col lg={7} md={8} sm={10} className='mb-4'>
                    <div className='rp_singleinput_holder'>
                    <p className='rp_label mb-2'>Network name</p>
                    <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                        <input type='text' placeholder='Network name' className='rp_singleInput flex-grow-1' />
                    </div>
                </div>
                    </Col>

                    <Col lg={7} md={8} sm={10} className='mb-4'>
                    <div className='rp_singleinput_holder'>
                    <p className='rp_label mb-2'>New RPC URL</p>
                    <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                        <input type='text' placeholder='New RPC URL' className='rp_singleInput flex-grow-1' />
                    </div>
                </div>
                    </Col>

                    <Col lg={7} md={8} sm={10} className='mb-4'>
                    <div className='rp_singleinput_holder'>
                    <p className='rp_label mb-2'>New RPC URL</p>
                    <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                        <input type='text' placeholder='New RPC URL' className='rp_singleInput flex-grow-1' />
                    </div>
                </div>
                    </Col>

                    <Col lg={7} md={8} sm={10} className='mb-4'>
                    <div className='rp_singleinput_holder'>
                    <p className='rp_label mb-2'>Currency Symbol</p>
                    <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                        <input type='text' placeholder='Currency Symbol' className='rp_singleInput flex-grow-1' />
                    </div>
                </div>
                    </Col>

                    <Col lg={7} md={8} sm={10} className='mb-4'>
                    <div className='rp_singleinput_holder'>
                    <p className='rp_label mb-2'>Block Explorer URL (Optional)</p>
                    <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                        <input type='text' placeholder='URL' className='rp_singleInput flex-grow-1' />
                    </div>
                </div>
                    </Col>

                   
                </Row>

                <div className='d-flex gap-3'>
                    <button className='secondary_btn'>Cancel</button>
                    <button className='orange_primary_btn'>Save</button>
                </div>
                </div>
        </Col>
    </Row>
    </Container>
    </>
  )
}

export default AddNetwork