import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import React from 'react';
import isEmpty from 'is-empty';


export function PrivateRoute({ children }) {

  // the user verification logic is up to your application
  // this is an example based on your code above 
  const loggedin = useSelector(state => state.loggedin);
  const rememberMe = localStorage.getItem('token');

  //    if (!isEmpty(rememberMe)) {
  //     return <Navigate to={'/home'} />;
  //     }
  if (!isEmpty(rememberMe)) {
    // render the wrapped page
    return children;
  }
  else {
    // user not logged in, redirect to the Login page which is unprotected
    return <Navigate to={'/'} />;
  }
}