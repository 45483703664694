import React, { useState, useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Header from '../Components/Header'
import Sidebar from '../Components/Sidebar'
import { toastAlert } from "../lib/toastAlert";
import { AddSellLink} from '../api/sellLink'
import { NavLink ,useNavigate } from 'react-router-dom'
import isEmpty from "is-empty";

const AddSellList = () => {
    const initialValue = {
        sellink:""
      };
      const [formValue, setFormValue] = useState(initialValue);
      const [errors, setError] = useState()
      const navigate = useNavigate();


    const handlechange = (event) => {
        setError({})
        var { name, value } = event.target;
        setFormValue({ ...formValue, [name]: value });
    }


    const validation = () => {
        let errors = {};
        var allowedExtensions =  `https?://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|https?://(?:www.|(?!www))[a-zA-Z0-9]+.[^s]{2,}|www.[a-zA-Z0-9]+.[^s]{2,}`;
        if (isEmpty(formValue?.sellink)) {
            errors.sellink = "sellink field is required";
        }
        if (!isEmpty(formValue.sellink)) {
            if (isEmpty(formValue?.sellink?.match(allowedExtensions))) {
                errors.sellink = "Invalid Format";
            }
        }

        return errors
    }

    const handleSubmit = async () => {
        try {
            var value = validation()
            if (!isEmpty(value)) {
                setError(value)
            }
            else {
              

                let { status, loading, error, message, result } = await AddSellLink(formValue);
                console.log("messagemessage", message, status);
                if (status) {
                    toastAlert("success", message);
                    setError({})
                    navigate('/sellList')
                } else {
                    if (error) {
                        setError(error);
                    } else if (message) {
                          toastAlert("error", message);
                    }
                }
            }
        } catch (err) {
            console.log("handleSubmit err", err);
        }
    }

  return (
    <>
    <Container fluid className='common_bg position-relative'>
        <div className='liner'></div>
        <Row>
            <Col xl={2} lg={0} className='p-0 d-none d-xl-block'>
                <Sidebar/>
            </Col>
            <Col xl={10} lg={12}>
    <Header title={'Add Sell Link'}/>
    <div className='mt-5 profile_holder'>
                <Row>
                    <Col lg={7} className='mb-4'>
                    <div className='rp_singleinput_holder'>
                    <p className='rp_label mb-2'>Title</p>
                    <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                        <input type='text' placeholder='Sell Link' className='rp_singleInput flex-grow-1' 
                        name="sellink"
                        value={formValue.sellink}
                        onChange={(event) => handlechange(event)}
                       
                        />

                    </div>
                    <span className="errors_style" id="sellink-error" >{errors && errors.sellink}</span>

                </div>
                    </Col>
                    </Row>
                    <button className='orange_small_primary' onClick={() => handleSubmit()}>Submit</button>
                    </div>
    </Col>
    </Row>
        </Container>
        </>
  )
}

export default AddSellList