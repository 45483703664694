import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Sidebar from '../Components/Sidebar'
import Header from '../Components/Header'
import Lottie from "lottie-react";
import bitcoin from '../assets/lotties/ye.json'
import thunder from '../assets/lotties/u.json'
import RemoveModal from '../Modals/RemoveModal';
import { NavLink } from 'react-router-dom';

function Network() {
    // modal
   const [show, setShow] = useState(false);

   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);

    const [data,setData] = useState([
        {
            bitcoinImg: require('../assets/images/bitcoin.svg').default,
            thunder: thunder,
            bitcoin : bitcoin,
            coinName : 'bitcoin',
            date : 'Feb 03 . 10:43 AM',     
            thunderImg: require('../assets/images/thunder.svg').default,       
        },
        {
            bitcoinImg: require('../assets/images/bitcoin.svg').default,
            thunder: thunder,
            bitcoin : bitcoin,
            coinName : 'bitcoin',
            date : 'Feb 03 . 10:43 AM',     
            thunderImg: require('../assets/images/thunder.svg').default,       
        }, 
        {
            bitcoinImg: require('../assets/images/bitcoin.svg').default,
            thunder: thunder,
            bitcoin : bitcoin,
            coinName : 'thunder',
            date : 'Feb 03 . 10:43 AM',     
            thunderImg: require('../assets/images/viothunder.svg').default,       
        },
    ])
  return (
    <>
    <Container fluid className='common_bg position-relative'>
    <div className='liner'></div>
    <Row>

    <Col xl={2} lg={0} className='p-0 d-none d-xl-block'>
        <Sidebar/>
    </Col>
    <Col  xl={10} lg={12}>
        <Header title={'Bitcoin Network'} />

        <div className='common_page_scroller mt-5 pe-2'>
            {data?.map((item) =>
            <div className='dashboard_box rounded-3 p-3 mb-3'>
                <Row>
                    <Col sm={6} className='mb-3 mb-sm-0'>
                        <div className='network_bar_left d-flex justify-content-center justify-content-sm-start align-items-center gap-3'>
                    <div className='dash_lottie_holder position-relative'>
                    <Lottie animationData={item.coinName == 'bitcoin' ? item.bitcoin : item.coinName == 'thunder' ? item.thunder : ''} className="dash_lottie" loop={true} />
                    <img src={item.coinName == 'bitcoin' ? item.bitcoinImg : item.coinName == 'thunder' ? item.thunderImg : ''} className='img-fluid dash_coinImg' />
                    </div>
                    <div className='dash_table_statusHolder'>
                                <p className='dash_table_status'>{item.coinName == 'bitcoin' ? 'BTC Network' : item.coinName == 'thunder' ? 'Lighting Network' : '' }</p>
                                <p className='dash_graymed_text'>{item.date}</p>
                            </div>
                            </div>
                    </Col>
                    <Col sm={6} className='text-center text-sm-end'>
                        <button className='orange_btn' onClick={handleShow}>Remove</button>
                    </Col>
                </Row>
            </div>
             )}
<div className='text-center text-sm-start'>
    <NavLink to='/addNetwork' className='sidebar_links'>
             <button className='orange_primary_btn'>+ Add more</button>
             </NavLink>
             </div>
        </div>

    </Col>
    </Row>
    </Container>

    {/* modals */}
                <RemoveModal show={show} handleClose={handleClose} />
    {/* end of modals */}

    </>
  )
}

export default Network