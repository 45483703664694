import React, { useState, useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Sidebar from '../Components/Sidebar'
import Header from '../Components/Header'
import ReactDatatable from '@ashvin27/react-datatable';
import { NavLink } from 'react-router-dom';
import EditBuyLink from '../Modals/EditBuyLink';
import DeleteBuyLink from '../Modals/DeleteBuyLink';
import { toastAlert } from "../lib/toastAlert";
import { getLink, DeleteBuylink } from '../api/buylink'

const BuyList = () => {

    // edit state
    const [showEdit, setShowEdit] = useState(false)
    const handleShowEdit = () => setShowEdit(true)
    const handleCloseEdit = () => setShowEdit(false)
    const [errors, setError] = useState()
    const [showDelete, setShowDelete] = useState(false)
    const handleShowDelete = () => setShowDelete(true)
    const handleCloseDelete = () => setShowDelete(false)
    const [editData, setEditData] = useState({})
    const [list, setList] = useState([])

    const state = {
        records: [
            {
                'sno': <p className='exchange_table_values text-center'>1</p>,
                "buyLink": <p className='exchange_table_values text-center'>https://dribbble.com/tags/notification-panel</p>,

            },
            {
                'sno': <p className='exchange_table_values text-center'>2</p>,
                "buyLink": <p className='exchange_table_values text-center'>https://dribbble.com/tags/notification-panel</p>,
            },

        ]
    }

    const columns = [
        {
            key: "",
            text: "SNO",
            align: "center",
            sortable: true,
            cell: (record, index) =>
                <p className='text-center'>{index + 1}
                </p>
        },
        {
            key: "buyLink",
            text: "Buy Link",
            className: "buyLink",
            align: "center",
            sortable: false,

        },
        {
            key: "action",
            text: "Action",
            className: "activity",
            align: "center",
            sortable: false,
            cell: (record) => {
                return (
                    <div className='d-flex justify-content-center align-items-center gap-2'>
                        <button className='cmn_plain_btn'
                            onClick={() => { setEditData(record); handleShowEdit() }}
                        >
                            <img src={require('../assets/images/editer.svg').default} className='img-fluid table_activity_img' /> </button>
                        <button className='cmn_plain_btn'
                            onClick={() => { onsubmit(record?._id); handleShowDelete() }}
                        ><img src={require('../assets/images/trash.svg').default} className='img-fluid table_activity_img' /> </button>
                    </div>
                )
            }
        },
    ];

    const config = {
        page_size: 10,
        length_menu: [10, 20, 50],
        filename: "Emailtemplates",
        no_data_text: "No Email Templates found!",
        language: {
            length_menu: "Show _MENU_ result per page",
            filter: "Filter in records...",
            info: "Showing _START_ to _END_ of _TOTAL_ records",
            pagination: {
                first: "First",
                previous: "Previous",
                next: "Next",
                last: "Last",
            },
        },
        show_length_menu: false,
        show_filter: false,
        show_pagination: false,
        show_info: false,
    };

    const extraButtons = [
        {
            className: "btn btn-primary buttons-pdf",
            title: "Export TEst",
            children: [
                <span>
                    <i className="glyphicon glyphicon-print fa fa-print" aria-hidden="true"></i>
                </span>
            ],
            onClick: (event) => {
                console.log(event);
            },
        },
        {
            className: "btn btn-primary buttons-pdf",
            title: "Export TEst",
            children: [
                <span>
                    <i className="glyphicon glyphicon-print fa fa-print" aria-hidden="true"></i>
                </span>
            ],
            onClick: (event) => {
                console.log(event);
            },
            onDoubleClick: (event) => {
                console.log("doubleClick")
            }
        },
    ]
    useEffect(() => {
        listlink();
    }, [])


    const listlink = async () => {
        try {
            let { status, loading, error, message, result } = await getLink();
            if (status) {
                console.log("resultresult", result);

                toastAlert("successData", result);
                setList(result)
            } else {
                if (error) {
                    setError(error);
                } else if (message) {
                    toastAlert("error", message);
                }
            }
        } catch (err) {
            console.log("listlink err", err);

        }
    }

    onsubmit = async (record) => {
        try {
            console.log("record11", record);
            var data = {
                id: record
            }
            var check = window.confirm("Are you sure you want to remove this plan");
            if (check) {
                const { status, loading, message, result, error } = await DeleteBuylink(data);
                if (status) {
                    toastAlert("success", message);
                    setError({})
                    listlink()
                } else {
                    if (error) {
                        setError(error);
                    } else if (message) {
                        toastAlert("error", message);
                    }
                }
            }
        } catch (err) {
            console.log("del err", err);
        }
    }

    return (
        <>
            <Container fluid className='common_bg position-relative'>
                <div className='liner'></div>
                <Row>
                    <Col xl={2} lg={0} className='p-0 d-none d-xl-block'>
                        <Sidebar />
                    </Col>
                    <Col xl={10} lg={12}>
                        <Header title={'Buy List'} />
                        <div className='text-end mt-5'>
                            <NavLink to='/addBuyLink' className='sidebar_links'>
                                <button className='orange_small_primary'>Add</button>
                            </NavLink>
                        </div>
                        <div className='common_page_scroller mt-4'>
                            <div className='exchange_table_holder dashboard_box rounded-3'>
                                <ReactDatatable
                                    config={config}
                                    records={list}
                                    columns={columns}
                                    extraButtons={extraButtons}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>

            </Container>

            {/* modals */}
            <EditBuyLink show={showEdit} handleClose={handleCloseEdit} getList={() => { listlink() }} editData={editData} />
            <DeleteBuyLink show={showDelete} handleClose={handleCloseDelete} />
        </>
    )
}

export default BuyList